import { useEthers } from '@usedapp/core';
import { ethers } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { HERC20Interface__factory } from '@/contracts/typechain';
import { useIsMounted } from '../useIsMounted';

const WETH = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
const YEN_WETH_PAIR = '0xA477f6cE86affC7B5c95924600b54F844304091C';

export const useYenPrice = () => {
  const [yenPrice, setYenPrice] = useState(ethers.constants.Zero);
  const { library } = useEthers();
  const isMounted = useIsMounted();

  const yenContract = useMemo(() => {
    if (!(process.env.REACT_APP_CONTRACT_ADDRESS && library?.getSigner)) {
      return null;
    }
    return HERC20Interface__factory.connect(process.env.REACT_APP_CONTRACT_ADDRESS, library!.getSigner());
  }, [library]);

  const WETHContract = useMemo(() => {
    if (!(WETH && library?.getSigner)) {
      return null;
    }
    return HERC20Interface__factory.connect(WETH, library!.getSigner());
  }, [library]);

  useEffect(() => {
    const fetchData = async () => {
      if (yenContract && WETHContract) {
        let price = ethers.constants.Zero;

        const yenBalance = await yenContract.balanceOf(YEN_WETH_PAIR);
        const wethBalance = await WETHContract.balanceOf(YEN_WETH_PAIR);
        if (yenBalance.gt(ethers.constants.Zero) && wethBalance.gt(ethers.constants.Zero)) {
          // yen price in wei
          price = wethBalance.mul(ethers.constants.WeiPerEther).div(yenBalance);
        }
        if (isMounted.current) {
          setYenPrice(price);
        }
      }
    };
    fetchData();
  }, [yenContract, WETHContract, isMounted]);

  return yenPrice;
};
