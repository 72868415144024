import React, { useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import Stake from './components/stake';
import Unstake from './components/unstake';
import { BigNumber, ethers, utils } from 'ethers';
import BN from 'bignumber.js';
import { useEthers } from '@usedapp/core';
import useContract from '@/hooks/chain/useContract';
import Countdown from 'react-countdown';
import usePoolContract from '@/hooks/chain/usePoolContract';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useUpdate } from '@/hooks/useUpdate';
import { useYenPrice } from '@/hooks/chain/useYenPrice';
import useDistInfo from '@/hooks/useDistInfo';

function Index() {
  const { account, library } = useEthers();
  const { getFundBalance } = useContract();
  const { getStakedYenValues, getStakeRecord } = usePoolContract();
  const [totalStakedYen, setTotalStakedYen] = useState(BigNumber.from(0));
  const [stakeRecord, setStakeRecord] = useState<{ [key: string]: BigNumber }>({});
  const [fund, setFund] = useState('');
  const isMounted = useIsMounted();
  const { updateByTimer } = useUpdate();
  const yenPrice = useYenPrice();
  const { nextDistTime, round } = useDistInfo();

  const descList = useMemo(() => {
    const willReceive =
      totalStakedYen.gt(0) && stakeRecord.stakedYen?.gt(0) && fund
        ? utils.parseEther(fund).mul(5).div(100).mul(stakeRecord.stakedYen).div(totalStakedYen)
        : ethers.constants.Zero;

    const apy =
      yenPrice.gt(0) && stakeRecord.stakedYen?.gt(0)
        ? willReceive
            .mul(ethers.constants.WeiPerEther)
            .mul(ethers.constants.WeiPerEther)
            .div(yenPrice.mul(stakeRecord.stakedYen))
            .mul(365)
            .mul(100)
        : ethers.constants.Zero;

    return [
      {
        label: 'Total YEN staked',
        value: totalStakedYen.gt(0) ? new BN(utils.formatEther(totalStakedYen)).dp(9).toFormat().toString() : '--',
        key: '1',
      },
      {
        label: 'You will receive',
        value: `${willReceive.gt(0) ? new BN(utils.formatEther(willReceive)).dp(9).toFormat().toString() : '--'} ETH`,
        key: '2',
      },
      {
        label: 'Your APY',
        value: `50000+%`,//`${apy.gt(0) ? new BN(utils.formatEther(apy)).dp(9).toFormat().toString() : '--'}%`,
        key: '3',
      },
      { label: 'Div Countdown', value: <Countdown date={nextDistTime} key={nextDistTime} />, key: '4' },
    ];
  }, [fund, stakeRecord.stakedYen, totalStakedYen, yenPrice, nextDistTime]);

  const tabItems = [
    { label: `Stake`, key: '1', children: <Stake /> },
    { label: `Unstake`, key: '2', children: <Unstake stakeRecord={stakeRecord} /> },
  ];

  useEffect(() => {
    const fetch = async () => {
      try {
        const _stakedYenValues = await getStakedYenValues();
        const _stakeRecord = await getStakeRecord();
        if (!isMounted.current) {
          return;
        }
        const _totalStakedYen = _stakedYenValues.reduce((accum, item) => accum.add(item), BigNumber.from(0));
        setTotalStakedYen(_totalStakedYen);
        setStakeRecord(_stakeRecord);
      } catch (error) {
        console.error(error);
      }
    };

    fetch();
  }, [getStakedYenValues, getStakeRecord, isMounted, updateByTimer]);

  useEffect(() => {
    if (account && library) {
      getFundBalance().then((res) => {
        if (res && isMounted.current) {
          setFund(utils.formatEther(res));
        }
      });
    }
  }, [account, library, getFundBalance, updateByTimer, isMounted]);

  return (
    <div className="'min-w-[100vw] flex flex-col justify-start items-center h-full space-y-3">
      <div className="py-2 text-center text-white bg-[#272932] w-full flex flex-col">
        <span>YEN FUND</span>
        <span className="text-2xl font-bold">{fund ? `${Math.floor(+fund * 1000) / 1000} ETH` : '--'}</span>
        <span className="pt-2 text-sm font-light text-gray-500">5% is used for each round</span>
      </div>
      <div className="bg-[#272932] rounded-xl p-4 w-[400px] max-w-[95vw] text-center flex flex-col items-center space-y-2">
        <div>Round {round} Dividends Pool</div>
        <span className="text-2xl font-bold text-[#F2B413]">
          {fund ? `${Math.floor(+fund * 10000 * 0.05) / 10000} ETH` : '--'}
        </span>
        <div className="bg-[#272932] rounded-xl p-4  w-full space-y-2">
          {descList.map((item) => {
            return (
              <div key={item.key} className="flex justify-between">
                <span>{item.label}</span>
                <span>{item.value}</span>
              </div>
            );
          })}
        </div>
      </div>

      <Tabs defaultActiveKey="1" type="card" className="w-[400px] max-w-[95vw] " items={tabItems} />
    </div>
  );
}

export default Index;
