import React, { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import { message } from 'antd';
import { BigNumber, utils } from 'ethers';
import Countdown from 'react-countdown';
import usePoolContract from '@/hooks/chain/usePoolContract';
import { useEthers } from '@usedapp/core';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useUpdate } from '@/hooks/useUpdate';

function Index({ stakeRecord }: { stakeRecord: { [key: string]: BigNumber } }) {
  const { account } = useEthers();
  const { unstake, unstakeFetch, claim } = usePoolContract();
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [claimData, setClaimData] = useState<any>({});
  const { updateByTimer } = useUpdate();

  const fetchClaim = useCallback(async (account: string) => {
    let data = {};
    if (!utils.isAddress(account)) {
      return data;
    }
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/claim?address=${account}`);
      if (res.status === 200) {
        data = res.data;
      }
    } catch (error) {
      console.log(error);
    }

    return data;
  }, []);

  const handleUnstakeAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number.isNaN(Number(value))) {
      return;
    }
    setUnstakeAmount(value);
  };

  const handleUnstake = async () => {
    if (!account) {
      message.info('Please connect to wallet');
      return;
    }
    if (!unstakeAmount || !utils.parseEther(unstakeAmount)?.gt(0)) {
      message.info('Please enter a valid unstake amount');
      return;
    }
    if (!stakeRecord?.stakedYen?.gt(0) || utils.parseEther(unstakeAmount)?.gt(stakeRecord.stakedYen)) {
      message.info('Not Enough YEN to unstake');
      return;
    }
    try {
      setIsLoading(true);
      await unstake(utils.parseEther(unstakeAmount));
      message.info('Unstake successfully');
    } catch (error) {
      console.log(error);
      message.info('Unstake failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnstakeFetch = async () => {
    if (!account) {
      message.info('Please connect to wallet');
      return;
    }
    if (!stakeRecord?.unstakeAmount?.gt(0)) {
      message.info('Nothing to withdraw');
      return;
    }
    if (stakeRecord?.unstakeTimestamp?.add(86400)?.mul(1000)?.gt(Date.now())) {
      message.info('Cannot withdraw now');
      return;
    }
    try {
      await unstakeFetch();
      message.info('Withdraw successfully');
    } catch (error) {
      console.log(error);
      message.info('Withdraw failed');
    }
  };

  const handleClaim = async () => {
    if (!account) {
      message.info('Please connect to wallet');
      return;
    }
    if (!(claimData.amount && BigNumber.from(claimData.amount).gt(0))) {
      message.info('Nothing to withdraw');
      return;
    }

    try {
      await claim(
        account,
        BigNumber.from(claimData.amount),
        claimData.days.map((item: number) => BigNumber.from(item)),
        claimData.v,
        claimData.r,
        claimData.s,
      );
      message.info('Withdraw successfully');
    } catch (error) {
      console.log(error);
      message.info('Withdraw failed');
    }
  };

  const descList = [
    {
      label: 'Staked',
      value: `${stakeRecord?.stakedYen?.gt(0) ? utils.formatEther(stakeRecord.stakedYen) : '--'} YEN`,
      key: '1',
      withdram: false,
    },
    {
      label: 'Countdown',
      value: stakeRecord?.unstakeTimestamp?.gt(0) ? (
        <Countdown
          date={stakeRecord.unstakeTimestamp.add(86400).mul(1000).toNumber()}
          key={stakeRecord.unstakeTimestamp.add(86400).mul(1000).toNumber()}
        >
          <span>Withdraw Now</span>
        </Countdown>
      ) : (
        '--'
      ),
      key: '2',
      withdram: false,
    },
    {
      label: 'Unstaked',
      value: `${stakeRecord?.unstakeAmount?.gt(0) ? utils.formatEther(stakeRecord.unstakeAmount) : '--'} YEN`,
      key: '3',
      withdram: true,
      onClick: handleUnstakeFetch,
    },
    {
      label: 'Dividends',
      value: `${
        claimData.amount && BigNumber.from(claimData.amount).gt(0) ? utils.formatEther(claimData.amount) : '--'
      } ETH`,
      key: '4',
      withdram: true,
      onClick: handleClaim,
    },
  ];

  useEffect(() => {
    if (account) {
      fetchClaim(account).then((res) => {
        setClaimData(res);
      });
    }
  }, [fetchClaim, account, updateByTimer]);

  return (
    <div className="w-full bg-[#272932] rounded-xl p-4 space-y-4 ">
      <div className="flex justify-between text-sm">
        <span>YEN Staked</span>
        <span>{`${stakeRecord?.stakedYen?.gt(0) ? utils.formatEther(stakeRecord.stakedYen) : '--'} YEN`}</span>
      </div>
      <div className="bg-[#181A21] rounded-md p-1 flex justify-center align-middle items-center px-4">
        <div className="text-sm text-gray-400 text-start mr-2">Amount</div>
        <Input className="border-none outline-none" value={unstakeAmount} onChange={handleUnstakeAmountChange} />
      </div>
      <div className="flex justify-center align-middle">
        <button
          onClick={handleUnstake}
          className="border bg-white text-[#159374] border-[#159374] rounded-full  py-1  text-center w-1/2"
        >
          Unstake {isLoading && <LoadingOutlined />}
        </button>
      </div>
      <div className="bg-[#272932] rounded-xl p-4  w-full space-y-2 text-sm font-line">
        {descList.map((item) => {
          return (
            <div key={item.key} className="flex justify-between text-end">
              <span>{item.label}</span>
              <p>
                {item.value}
                <br />
                {item.withdram ? (
                  <span className="text-[#159374] cursor-pointer" onClick={item.onClick}>
                    Withdraw
                  </span>
                ) : (
                  ''
                )}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Index;
