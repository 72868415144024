import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import useContract from '@/hooks/chain/useContract';
import { useUpdate } from '@/hooks/useUpdate';

function Dialog(props: any) {
  const { updateByTimer } = useUpdate();
  const { account, library } = useEthers();
  const { getCurrentAPY } = useContract();
  const [descList, setDescList] = useState<any>([
    { label: 'Amount', value: '--', key: '1' },
    { label: 'Term,days', value: '10', key: '2' },
    { label: 'APY', value: '--', key: '3' },
  ]);

  const [APY, setAPY] = useState<string | number>('');

  useEffect(() => {
    if (account && library) {
      getCurrentAPY().then((res) => {
        if (res) {
          const APYValue = res.APY ? res.APY.toNumber() : '--';
          setDescList([
            { label: 'Amount', value: '--', key: '1' },
            { label: 'Term,days', value: '10', key: '2' },
            { label: 'APY', value: APYValue, key: '3' },
          ]);
          setAPY(APYValue);
        }

        // if (res) {
        //   setDescList(res);
        // }
      });
    }
  }, [account, library, getCurrentAPY, updateByTimer]);

  return (
    <Modal
      open={props.open}
      onOk={props.onOk}
      width="400px"
      style={{ top: 200 }}
      footer={null}
      destroyOnClose={true} //关闭时销毁子元素
      maskClosable={false}
      zIndex={100000}
      closable={false}
    >
      <div className="m-0 space-y-4 ">
        <div className="pb-10 text-center">
          Current APY <span className="text-[#159374] ">{APY}%</span>
        </div>
        <div>
          {descList.map((item: any) => {
            return (
              <div
                key={item.key}
                className="flex justify-between p-2 border border-white border-dashed border-opacity-10"
              >
                <span>{item.label}</span>
                <span>{item.value}</span>
              </div>
            );
          })}
        </div>
        <div className="w-full h-1 my-5 bg-gray-300" />
        <p className="text-sm font-thin text-center text-gray-300">
          Wait 10 days until stake Maturity Date <br />
          OR
          <br /> End stake early without penalty
        </p>
        <div className="flex justify-center align-middle">
          <button
            onClick={props.onCancel}
            className="border bg-white text-[#159374] border-[#159374] rounded-full  py-1  text-center w-1/2"
          >
            End Stake Early
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default Dialog;
