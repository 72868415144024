import React, { useState, useEffect } from 'react';
import Header from '../Header';
import MobileHeader from '../MobileHeader';
import Footer from '../Footer';
import { Layout, Alert } from 'antd';
import { Outlet } from 'react-router-dom';
import { useEthers } from '@usedapp/core';

import useContract from '@/hooks/chain/useContract';

function DefaultLayout() {
  const { isCurChain } = useContract();
  const [curChain, setCurChain] = useState(false);
  const { account, library, switchNetwork } = useEthers();

  useEffect(() => {
    if (account && library) {
      isCurChain().then((res: any) => {
        setCurChain(res);
        if (res == false) {
          switchNetwork(1);
        }
      });
    }
  }, [account, library, isCurChain, switchNetwork]);

  return (
    <Layout className="w-[100vw] min-h-[100vh] flex flex-col bg-[#1e2028] overflow-hidden">
      <div className="sm:hidden">
        <Header></Header>
        {!curChain ? <Alert message="Please switch to Ethereum Mainnet" className="font-bold text-center"></Alert> : ''}
      </div>
      <div className="hidden sm:block">
        <MobileHeader></MobileHeader>
      </div>
      <div className="flex-1 ">
        <Outlet />
      </div>
      <Footer></Footer>
    </Layout>
  );
}

export default DefaultLayout;
