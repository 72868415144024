import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import Claim from './components/claim';

import { BigNumber } from 'ethers';
import { useEthers } from '@usedapp/core';
import useContract from '@/hooks/chain/useContract';
import addYenToMetaMask from '@/utils';
import { useUpdate } from '@/hooks/useUpdate';

function Index() {
  const { account, library } = useEthers();
  const [globalRank, setGlobalRank] = useState<BigNumber>();

  const { getGlobalRank, getCurrentMint } = useContract();

  const [mintInfo, setMintInfo] = useState<any>('');

  const { updateByTimer } = useUpdate();

  useEffect(() => {
    if (account && library) {
      getGlobalRank().then((res) => setGlobalRank(res));
      getCurrentMint().then((res: any) => {
        if (res) {
          setMintInfo(res);
        }
      });
    }
  }, [account, library, getCurrentMint, getGlobalRank, updateByTimer]);

  // const onChange = (key: string) => {
  //   console.log(key);
  // };

  // const tabItems = [
  //   {
  //     label: `Claim`,
  //     key: '1',
  //     children: claim,
  //   },
  //   // { label: `Claim + Share`, key: '2', children: share },
  //   // { label: `Claim + Stake`, key: '3', children: stake },
  // ];

  return (
    <div className="'min-w-[100vw] flex flex-col justify-center items-center h-full space-y-3 ">
      <div className="mt-4 text-2xl font-semibold text-center text-white">Mint YEN</div>
      <div className="bg-[#272932] rounded-xl overflow-hidden">
        <div className=" p-4 w-[400px]  max-w-[95vw] text-center  text-sm flex flex-col items-center space-y-2">
          <div className="w-full">
            GlobalRank <span className="text-[#159374]">{globalRank?.toNumber()}</span>
            {/* <div className="float-right w-3">
              <Image src={shareSvg} />
            </div> */}
          </div>
          <div className="bg-[#272932] w-[300px] ">
            <div className="flex justify-between p-2 border border-white border-dashed border-opacity-10">
              <span>cRank</span>
              <span>{mintInfo.cRank ? mintInfo.cRank.toNumber() : '-'}</span>
            </div>
            <div className="flex justify-between p-2 border border-white border-dashed border-opacity-10">
              <span>AMP</span>
              <span>{mintInfo.AMP ? mintInfo.AMP.toNumber() : '-'}</span>
            </div>
            <div className="flex justify-between p-2 border border-white border-dashed border-opacity-10">
              <span>EAA</span>
              <span>{mintInfo.EAA ? mintInfo.EAA.toNumber() / 10 : '-'}%</span>
            </div>
            <div className="flex justify-between p-2 border border-white border-dashed border-opacity-10">
              <span>Term,day</span>
              <span>{mintInfo.term ? mintInfo.term.toNumber() : '-'}</span>
            </div>
            <div className="flex justify-between p-2">
              <span>Estimated YEN</span>
              <span>{mintInfo.estimated ? mintInfo.estimated.toNumber() : '-'}</span>
            </div>
            <div>
              <Progress
                percent={(1 - (mintInfo.endtime * 1000 - +new Date()) / (mintInfo.term * 86400000)) * 100 || 0}
                showInfo={false}
                strokeColor="#159374"
                strokeWidth={2}
              />
            </div>
          </div>
        </div>
        <div className="bg-[#22252f] w-full text-center space-y-4 pb-5">
          {/* <Tabs defaultActiveKey="1" onChange={onChange} centered className="">
            {tabItems.map((item) => {
              return (
                <Tabs.TabPane tab={item.label} key={item.key}>
                  <item.children />
                </Tabs.TabPane>
              );
            })}
          </Tabs> */}
          <Claim mintInfo={mintInfo} />
          <button
            className="border text-[#159374] border-[#159374] rounded-full  py-1  text-center w-1/2"
            onClick={addYenToMetaMask}
          >
            Add YEN to wallet
          </button>
        </div>
      </div>
    </div>
  );
}

export default Index;
