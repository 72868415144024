import React from 'react';
import ReactDOM from 'react-dom/client';
import { Config, DAppProvider, Mainnet } from '@usedapp/core';
import { HashRouter } from 'react-router-dom';
import App from './App';
import 'antd/dist/antd.less';

import './index.css';
import { ProvideUpdate } from './hooks/useUpdate';

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {},
  refresh: 'never',
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <ProvideUpdate>
        <HashRouter>
          <App />
        </HashRouter>
      </ProvideUpdate>
    </DAppProvider>
  </React.StrictMode>,
);
