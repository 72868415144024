import { DIST_INTERVAL, INIT_DIST_TIMESTAMP } from '@/common/constants/misc';
import { useCallback, useEffect, useState } from 'react';
import { useIsMounted } from './useIsMounted';

const useDistInfo = () => {
  const [nextDistTime, setNextDistTime] = useState(INIT_DIST_TIMESTAMP);
  const [round, setRound] = useState(1);
  const isMounted = useIsMounted();

  const calcRound = useCallback(() => {
    const now = Date.now();
    const diff = now - INIT_DIST_TIMESTAMP;
    if (diff <= 0) {
      return;
    }
    const _round = Math.ceil(diff / DIST_INTERVAL) + 1;
    const _nextDistTime = INIT_DIST_TIMESTAMP + (_round - 1) * DIST_INTERVAL;
    if (isMounted) {
      setRound(_round);
      setNextDistTime(_nextDistTime);
    }
  }, [isMounted]);

  useEffect(() => {
    calcRound();
  }, [calcRound]);

  useEffect(() => {
    const diff = nextDistTime - Date.now();
    const timer = setTimeout(calcRound, Math.abs(diff));
    return () => {
      clearTimeout(timer);
    };
  }, [nextDistTime, calcRound]);

  return { nextDistTime, round };
};

export default useDistInfo;
