import React from 'react';

import sharetype1 from '@/assets/sharetype1.svg';
import sharetype2 from '@/assets/sharetype2.svg';
const links = {
  display: 'flex',
  marginBottom: '10px',
};
function Footer() {
  const iconList = [
    { img: sharetype1, link: 'https://twitter.com/Real_YenCrypto' },
    { img: sharetype2, link: 'https://t.me/YenCrypto\n' },
    // { img: sharetype2, link: 'https://t.me/YENCryptoChannel' },
  ];
  // const displayAddress = useMemo(() => {
  //   if (!account) return null;
  //   return compareAddress(account);
  // }, [account]);

  return (
    <div className="flex flex-col items-center justify-center py-5 text-sm font-light sm:text-[6px]">
      <div className="space-x-5" style={links}>
        {iconList.map((item, index) => {
          return (
            <a href={item.link} target="_blank" key={index} rel={'noreferrer noopener'}>
              <img src={item.img} className="w-6 h-6" />
            </a>
          );
        })}
      </div>

      <div className={"flex space-x-5"}>
        <a className="text-center text-base font-semibold" href={"https://yen-crypto-1.gitbook.io/yen-crypto/"} target={"_blank"} rel={"noreferrer"}>
          Whitepaper
        </a>
        <a className="text-center text-base font-semibold" href={"https://www.cipheraudit.org/post/yen-crypto"} target={"_blank"} rel={"noreferrer"}>
          Audit Report
        </a>
      </div>

      <div>
        <span className="opacity-50">Contract Address:</span> {process.env.REACT_APP_CONTRACT_ADDRESS}
      </div>

      <div className="text-center opacity-50">Copyright © 2023 by Yen Crypto Foundation. All Rights Reserved</div>
      {/*<div className="flex justify-center mt-2 ">*/}
      {/*  <Space split={<Divider type="vertical" />}>*/}
      {/*    <a*/}
      {/*      href="https://t.me/YENCryptoTalk"*/}
      {/*      className="hover:text-white hover:opacity-75"*/}
      {/*      target="_blank"*/}
      {/*      rel="noreferrer"*/}
      {/*    >*/}
      {/*      TG group*/}
      {/*    </a>*/}
      {/*    <a*/}
      {/*      href="https://t.me/YENCryptoChannel"*/}
      {/*      className="hover:text-white hover:opacity-75"*/}
      {/*      target="_blank"*/}
      {/*      rel="noreferrer"*/}
      {/*    >*/}
      {/*      TG Channel*/}
      {/*    </a>*/}
      {/*    <a*/}
      {/*      href="https://twitter.com/YenNetwork"*/}
      {/*      className="hover:text-white hover:opacity-75"*/}
      {/*      target="_blank"*/}
      {/*      rel="noreferrer"*/}
      {/*    >*/}
      {/*      twitter*/}
      {/*    </a>*/}
      {/*  </Space>*/}
      {/*</div>*/}
    </div>
  );
}

export default Footer;
