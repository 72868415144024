import React from 'react';
import { Tabs } from 'antd';
import EventsItem from './components/EventsItem';

interface event {
  type: 'mint' | 'claim' | 'stake' | 'buy';
  price: string;
  address: string;
  day: string;
  key: string;
}

function Index() {
  const onChange = (key: string) => {
    console.log(key);
  };
  const tabItems = [
    { label: `Transactions`, key: '1' },
    { label: `Mint`, key: '2' },
    { label: `Claim`, key: '3' },
    { label: `Stake`, key: '4' },
  ];

  const eventsList: Array<event> = [];

  return (
    <div className="'min-w-[100vw] flex flex-col justify-top items-center h-full space-y-3 pt-5">
      <div className="text-2xl font-semibold text-center text-white">Events</div>
      <div className="w-[500px] max-w-[95vw]">
        <Tabs defaultActiveKey="1" onChange={onChange} centered className="">
          {tabItems.map((item) => {
            return (
              <Tabs.TabPane tab={item.label} key={item.key}>
                <div className="mt-5 space-y-2">
                  {eventsList.map((params) => {
                    return (
                      <EventsItem
                        type={params.type}
                        price={params.price}
                        address={params.address}
                        day={params.day}
                        key={params.key}
                      />
                    );
                  })}
                </div>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}

export default Index;
