import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';
import { shortenIfAddress, useEthers } from '@usedapp/core';
import { Drawer } from 'antd';
import { AlignLeftOutlined, UserOutlined } from '@ant-design/icons';

const menus = [
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Mint', link: '/mint' },
  { label: 'Stake', link: '/stake' },
  // { label: 'Events', link: '/events' },
  // { label: 'Whitepaper', href: 'https://yencrypto.xyz/docs/YencryptoWhitepaper.pdf', link: '' },
  // { label: 'Docs', href: 'https://yen-crypto.gitbook.io/yen-crypto/', link: '' },
];

function Header() {
  const [open, setOpen] = useState(false);
  const [openRight, setOpenRight] = useState(false);
  const { pathname } = useLocation();
  const { activateBrowserWallet, account } = useEthers();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const showDrawerRight = () => {
    setOpenRight(true);
  };
  const onCloseRight = () => {
    setOpenRight(false);
  };

  const displayAddress = useMemo(() => {
    if (!account) return null;
    // console.log(account);

    return shortenIfAddress(account);
  }, [account]);

  return (
    <div className="w-full bg-[#181A21]">
      <div className="flex justify-between px-5 py-2 text-2xl">
        <AlignLeftOutlined onClick={showDrawer} />
        <UserOutlined onClick={showDrawerRight} />
      </div>

      <Drawer placement="left" closable={false} onClose={onClose} open={open} width="200px">
        <div className="flex flex-col h-full overflow-hidden bg-black">
          {menus.map((item) => (
            <div
              className="p-5 font-bold transition-all cursor-pointer hover:text-red-300 hover:scale-110"
              key={item.label}
            >
              <span className={pathname == item.link ? 'font-bold text-[#159374]' : ''} onClick={onClose}>
                {/*{item.href ? (*/}
                {/*  <a href={item.href} target="_blank" rel="noopener noreferrer">*/}
                {/*    {item.label}*/}
                {/*  </a>*/}
                {/*) : (*/}
                {/*  <Link to={item.link}> {item.label}</Link>*/}
                {/*)}*/}
                {<Link to={item.link}> {item.label}</Link>}
              </span>
            </div>
          ))}
        </div>
      </Drawer>

      <Drawer placement="right" closable={false} onClose={onCloseRight} open={openRight} width="140px">
        <div className="flex flex-col content-center justify-start h-full px-2 pt-4 space-y-4 overflow-hidden bg-black">
          <div className="mr-5">YEN:0</div>
          <button
            className="border border-[#159374] rounded-full px-3 py-1 w-[100px] text-center"
            onClick={() => {
              message.info('Coming Soon!');
            }}
          >
            Ethereum
          </button>
          {displayAddress ? (
            <button className="bg-[#159374] rounded-full px-3 py-1 min-w-[100px] text-center">{displayAddress}</button>
          ) : (
            <button
              className="bg-[#159374] rounded-full px-3 py-1 min-w-[100px] text-center"
              onClick={() => {
                activateBrowserWallet();
              }}
            >
              Connect
            </button>
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default Header;
