import { BigNumber, ethers, BigNumberish } from 'ethers';
import { YENCrypto__factory } from '@/contracts/typechain';
import { useEthers } from '@usedapp/core';
import { useCallback } from 'react';

// Start Mining = claimRank
function useContract() {
  const { library, account } = useEthers();

  const isCurChain = useCallback(async () => {
    const clainId = await library?.getSigner().getChainId();
    return clainId == 1;
  }, [library]);

  const getContract = useCallback(() => {
    return YENCrypto__factory.connect(process.env.REACT_APP_CONTRACT_ADDRESS!, library!.getSigner());
  }, [library]);

  const getFundBalance = useCallback(async () => {
    return await library?.getBalance(process.env.REACT_APP_FUND_ADDRESS || '');
  }, [library]);

  const claimRank = async (term: string) => {
    const tx = await getContract().claimRank(BigNumber.from(term), {
      value: ethers.constants.WeiPerEther.mul(5).div(100),
    });

    await tx.wait();

    return tx;
  };

  const getGrossReward = useCallback(
    async (rankDelta: BigNumberish, amplifier: BigNumberish, term: BigNumberish, eaa: BigNumberish) => {
      const tx = await getContract().getGrossReward(rankDelta, amplifier, term, eaa);

      return tx;
    },
    [getContract],
  );

  const getGlobalRank = useCallback(async () => {
    const res = await getContract().globalRank();
    return res;
  }, [getContract]);

  const claimMintReward = async () => {
    // if (!isCurChain) return;
    const tx = await getContract().claimMintReward();

    await tx.wait();

    return tx;
  };

  const getCurrentAPY = useCallback(async () => {
    const APY = await getContract().getCurrentAPY();

    return { APY };
  }, [getContract]);

  const getStartTime = useCallback(async () => {
    const startTime = await getContract().startTime();

    return { startTime };
  }, [getContract]);

  const penalty = useCallback(
    async (secsLate: BigNumber) => {
      const [WITHDRAWAL_WINDOW_DAYS, MAX_PENALTY_PCT] = await Promise.all([
        getContract().WITHDRAWAL_WINDOW_DAYS(),
        getContract().MAX_PENALTY_PCT(),
      ]);
      const daysLate = secsLate.div(3600 * 24);
      if (daysLate.gt(WITHDRAWAL_WINDOW_DAYS.sub(1))) {
        return MAX_PENALTY_PCT;
      }
      const penalty = BigNumber.from(2).pow(daysLate.add(3)).div(WITHDRAWAL_WINDOW_DAYS).sub(1);
      return penalty.lt(MAX_PENALTY_PCT) ? penalty : MAX_PENALTY_PCT;
    },
    [getContract],
  );

  const getMintInfo = useCallback(async () => {
    const userMint = await getContract().getUserMint();
    const [, , endtime] = userMint;

    const AMP = userMint.amplifier;
    const EAA = userMint.eaaRate;
    const cRank = userMint.rank;
    const term = userMint.term;
    const user = userMint.user;

    return {
      AMP,
      EAA,
      cRank,
      term,
      endtime,
      user,
    };
  }, [getContract]);

  const getCurrentMint = useCallback(async () => {
    const [userMintHistory, globalRank] = await Promise.all([getMintInfo(), getContract().globalRank()]);
    const { cRank, AMP, term, EAA, endtime, user } = userMintHistory;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const timeDiff = BigNumber.from(currentTimestamp).sub(endtime);
    const secsLate = timeDiff.gt(0) ? timeDiff : BigNumber.from(0);
    const _penalty = await penalty(secsLate);
    const _rankDelta = globalRank.sub(cRank);
    const rankDelta = _rankDelta.gt(2) ? _rankDelta : BigNumber.from(2);
    const estimated = (await getGrossReward(rankDelta, AMP, term, EAA.add(1000)))
      .mul(BigNumber.from(100).sub(_penalty))
      .div(100);

    return {
      claimable: user !== ethers.constants.AddressZero && BigNumber.from(currentTimestamp).gt(endtime),
      estimated,
      ...userMintHistory,
    };
  }, [getContract, getGrossReward, getMintInfo, penalty]);

  const getDashboard = useCallback(async () => {
    const [activeMinters, genesisTs, totalSupply, AMP, EAA, APY, MaxTerm] = await Promise.all([
      getContract().activeMinters(),
      getContract().genesisTs(),
      getContract().totalSupply(),
      getContract().getCurrentAMP(),
      getContract().getCurrentEAAR(),
      getContract().getCurrentAPY(),
      getContract().getCurrentMaxTerm(),
    ]);

    return {
      activeMinters: activeMinters,
      genesisTs: genesisTs,
      totalSupply: totalSupply,
      AMP: AMP,
      EAA: EAA,
      APY: APY,
      MaxTerm: MaxTerm,
    };
  }, [getContract]);

  // const stakeEarly = async () => {
  //   const AMP = await getContract().getUserStake();
  // };

  // const stakeYen = async () => {};
  // const stakeYen = async () => {};

  // const getStakeInfo = async () => {
  //   const totalYenStaked = await getContract().totalYenStaked();
  //   // const APY = await getContract()();
  // };
  const getUserMint = async () => {
    const userMint = await getContract().getUserMint();
    return {
      userMint,
    };
  };

  // const getUserInfo = async () => {
  //   const totalYenStaked = await getContract();
  // };
  // const getEvent = async (event: string) => {
  //   const totalYenStaked = await getContract();
  // };

  const getYenBalance = useCallback(async () => {
    if (!account) {
      return ethers.constants.Zero;
    }
    const balance = getContract().balanceOf(account);
    return balance;
  }, [account, getContract]);

  const approve = useCallback(
    async (spender: string | undefined) => {
      if (!account || !spender) {
        return;
      }
      console.log(spender, ethers.constants.MaxUint256);
      const tx = await getContract().approve(spender, ethers.constants.MaxUint256);

      return tx.wait();
    },
    [account, getContract],
  );

  const getAllowance = useCallback(
    async (spender: string | undefined) => {
      if (!account || !spender) {
        return BigNumber.from(0);
      }
      const allowance = getContract().allowance(account, spender);
      return allowance;
    },
    [account, getContract],
  );

  return {
    claimRank,
    claimMintReward,
    getFundBalance,
    getGlobalRank,
    getDashboard,
    getCurrentAPY,
    getMintInfo,
    getGrossReward,
    getUserMint,
    getCurrentMint,
    getStartTime,
    isCurChain,
    getYenBalance,
    approve,
    getAllowance,
  };
}

export default useContract;
