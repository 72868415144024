import React from 'react';
import { Image } from 'antd';
import Mint from '@/assets/mint.svg';
import Claim from '@/assets/claim.svg';
import Stake from '@/assets/stake.svg';
import Buy from '@/assets/buy.svg';

interface props {
  type: 'mint' | 'claim' | 'stake' | 'buy';
  price: string;
  address: string;
  day: string;
}
// enum type {
//   'mint',
//   'claim',
//   'stake',
//   'buy',
// }

function Index(props: props) {
  // const onChange = (key: string) => {
  //   console.log(key);
  // };
  // const tabItems = [
  //   {
  //     label: `Claim`,
  //     key: '1',
  //   },
  //   { label: `Claim + Stake`, key: '2' },
  // ];
  const dom = {
    mint: Mint,
    claim: Claim,
    stake: Stake,
    buy: Buy,
  };

  const imageType = dom[props.type];

  return (
    <div className="flex w-full bg-[#272932] rounded-md space-x-2 items-center p-2 font-light text-sm">
      <div className="w-[100px] flex justify-start align-middle">
        <Image src={imageType}></Image>
      </div>{' '}
      <div className="flex-1 pl-8">
        {props.price}
        <br />
        {props.address}
      </div>
      <div>{props.day}</div>
    </div>
  );
}

export default Index;
