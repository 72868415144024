import React, { createContext, useContext, useEffect, useState } from 'react';

const updateContext = createContext({ updateByTimer: 0 });

export const useUpdate = () => useContext(updateContext);

const useProvideUpdate = () => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const timerId = setInterval(() => {
      if (isMounted) setTimer(Date.now());
    }, 3000);
    return () => {
      isMounted = false;
      clearInterval(timerId);
    };
  }, []);

  return { updateByTimer: timer };
};

export function ProvideUpdate({ children }: { children: any }) {
  const update = useProvideUpdate();
  return <updateContext.Provider value={update}>{children}</updateContext.Provider>;
}
