import React, { useEffect, useState } from 'react';
import { Input, Spin, Tooltip } from 'antd';
import { message } from 'antd';
import { useEthers } from '@usedapp/core';
import useContract from '@/hooks/chain/useContract';
import { BigNumber } from 'ethers';
import addYenToMetaMask from '../../utils/index';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useUpdate } from '@/hooks/useUpdate';

function Index() {
  const { account, library } = useEthers();
  const [globalRank, setGlobalRank] = useState<BigNumber>();
  const { getGlobalRank, claimRank, getStartTime } = useContract();
  const [value, setValue] = useState('100');
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState<BigNumber>(BigNumber.from(1698991200));
  const { updateByTimer } = useUpdate();

  const handleStartMining = () => {
    if (+new Date() / 1000 < startTime.toNumber()) {
      return message.warn('Not Start Yet');
    }

    if (!value) {
      return message.warn('Please enter');
    }

    setLoading(true);

    claimRank(value)
      .then(() => {
        // if (res) {
        //   navigate('/mint/detail');
        // }
        window.location.reload();
      })
      .catch((err) => {
        // message.error(err.error?.message || 'Request failed');
        if (err.error?.message == 'execution reverted: CRank: Mint already in progress') {
          // navigate('/mint/detail');
        } else {
          message.error(err.error?.message || 'Request failed');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (account && library) {
      getGlobalRank().then((res) => setGlobalRank(res));
      getStartTime().then((res) => setStartTime(res.startTime));
    }
  }, [account, library, getGlobalRank, getStartTime, updateByTimer]);

  return (
    <div className="'min-w-[100vw] flex flex-col justify-center items-center h-full space-y-3 mt-8">
      <div className="mt-4 text-2xl font-semibold text-center text-white">Mint YEN</div>

      {/*<div className="mt-4 text-md text-center text-white">Launch Time: {new Date(startTime as any * 1000).toUTCString()}</div>*/}
      <Spin tip="Loading..." spinning={loading}>
        <div className="bg-[#272932] rounded-xl p-4 w-[400px]  max-w-[95vw] text-center  text-sm flex flex-col items-center space-y-2">
          <div>
            GlobalRank <span className="text-[#159374]">{globalRank ? globalRank.toNumber() : '-'}</span>
          </div>
          <div className="text-xs">Term,days:</div>
          <div className="bg-[#181A21] rounded-full my-2 w-full py-2 px-4">
            <Input
              value={value}
              onInput={(e: any) => {
                setValue(e.target.value);
              }}
              className="border-none outline-none focus:outline-none"
            />
          </div>
          <button
            className="border text-[#159374] border-[#159374] bg-white rounded-full px-3 py-1 text-center w-1/2"
            onClick={() => {
              handleStartMining();
              // message.info('Coming Soon!');
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Start Mining
            <Tooltip title="Mint will cost 0.05 ETH and the fee goes into YEN Fund which will generate daily passive income for all YEN stakers.">
              <QuestionCircleOutlined className="ml-2" />
            </Tooltip>
          </button>
          <button
            onClick={addYenToMetaMask}
            className="border text-[#159374] border-[#159374] rounded-full  py-1  text-center w-1/2"
          >
            Add YEN to wallet
          </button>
        </div>
      </Spin>
    </div>
  );
}

export default Index;
