import React, { useEffect, useState } from 'react';
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom';
import { ethers } from 'ethers';

import DefaultLayout from '@/components/DefaultLayout';
import Dashboard from '@/views/Dashboard';
import Countdown from '@/views/Dashboard/countdown';
import Mint from '@/views/Mint';
import MintDetail from '@/views/Mint/detail';
import Stake from '@/views/Stake';
import Events from '@/views/Events';
import useContract from '@/hooks/chain/useContract';
import { useEthers } from '@usedapp/core';
import { useUpdate } from '@/hooks/useUpdate';

const redir = () => {
  const deadline = 1665928800000 + 86400 * 1000;
  const nowtime = +new Date();
  const isCountDown = deadline - nowtime >= 0;

  return isCountDown ? <Navigate to="/launch" replace /> : <Navigate to="/dashboard" replace />;
};

const Routes = () => {
  const [redirectMint, setRedirectMint] = useState(false);
  const { getCurrentMint } = useContract();
  const { account, library } = useEthers();

  const deadline = 1665928800000 + 86400 * 1000;
  const nowtime = +new Date();
  const isCountDown = deadline - nowtime >= 0;
  const { updateByTimer } = useUpdate();

  useEffect(() => {
    if (account && library && !isCountDown) {
      getCurrentMint()
        .then((res: any) => {
          if (res.user === ethers.constants.AddressZero) {
            console.log('No Mint');
            setRedirectMint(false);
          } else {
            setRedirectMint(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setRedirectMint(false);
        });
    }
  }, [account, library, isCountDown, getCurrentMint, updateByTimer]);

  // const redirMint = () => {
  //   return redirectMint ? <Navigate to="/mint/detail" replace /> : <Navigate to="/mint" replace />;
  // };

  return (
    <ReactRoutes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" key="index" element={redir()} />
        {isCountDown && <Route path="launch" key="Launch" element={<Countdown />} />}

        {/* <Route path="/" key="Index" element={<Index />} /> */}
        <Route path="dashboard" key="Dashboard" element={<Dashboard />} />
        <Route path="mint" key="Mint" element={redirectMint ? <MintDetail /> : <Mint />} />
        <Route path="stake" key="Stake" element={<Stake />} />
        <Route path="events" key="Events" element={<Events />} />
      </Route>
    </ReactRoutes>
  );
};

export default Routes;
