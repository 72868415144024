const addYenToMetaMask = async () =>{
    const tokenAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const tokenSymbol = 'YEN';
    const tokenDecimals = 18;

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
          },
        },
      });

      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Something is wrong!');
      }
    } catch (error) {
      console.log(error);
    }
}

export default addYenToMetaMask;