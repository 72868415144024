import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { message } from 'antd';
import { BigNumber, utils } from 'ethers';
import useContract from '@/hooks/chain/useContract';
import { useEthers } from '@usedapp/core';
import usePoolContract from '@/hooks/chain/usePoolContract';
import { LoadingOutlined } from '@ant-design/icons';
import { useUpdate } from '@/hooks/useUpdate';
import { useIsMounted } from '@/hooks/useIsMounted';

function Index() {
  const { account } = useEthers();
  const { getYenBalance, approve, getAllowance } = useContract();
  const { stake } = usePoolContract();
  const [yenAllowance, setYenAllowance] = useState(BigNumber.from(0));
  const [yenBalance, setYenBalance] = useState(BigNumber.from(0));
  const [stakeAmount, setStakeAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { updateByTimer } = useUpdate();
  const isMounted = useIsMounted();

  useEffect(() => {
    getYenBalance().then((res) => {
      if (isMounted.current) {
        setYenBalance(res);
      }
    });
    getAllowance(process.env.REACT_APP_POOL_CONTRACT_ADDRESS).then((res) => {
      if (isMounted.current) {
        setYenAllowance(res);
      }
    });
  }, [getYenBalance, getAllowance, updateByTimer, isMounted]);

  const handleStakeAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number.isNaN(Number(value))) {
      return;
    }
    setStakeAmount(value);
  };

  const handleStake = async () => {
    if (!account) {
      message.info('Please connect to wallet');
      return;
    }
    if (!yenAllowance?.gt(0)) {
      message.info('No allowance');
      return;
    }
    if (!stakeAmount || !utils.parseEther(stakeAmount).gt(0)) {
      message.info('Please enter a valid stake amount');
      return;
    }
    if (utils.parseEther(stakeAmount).gt(yenBalance)) {
      message.info('Not Enough YEN to stake');
      return;
    }
    try {
      setIsLoading(true);
      await stake(utils.parseEther(stakeAmount));
      message.info('Stake successfully');
    } catch (error) {
      console.log(error);
      message.info('Stake failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async () => {
    message.info('Coming Soon');
    return;
    if (!account) {
      message.info('Please connect to wallet');
      return;
    }
    try {
      setIsLoading(true);
      await approve(process.env.REACT_APP_POOL_CONTRACT_ADDRESS);
      message.info('Approve successfully');
    } catch (error) {
      console.log(error);
      message.info('Approve failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full bg-[#272932] rounded-xl p-4 space-y-4 ">
      <div className="flex justify-between text-sm">
        <span>Available YEN</span>
        <span>{yenBalance.gt(0) ? utils.formatEther(yenBalance) : '--'}</span>
      </div>
      <div className="bg-[#181A21] rounded-md p-1 flex justify-center align-middle items-center px-4">
        <div className="text-sm text-gray-400 text-start mr-2">Amount</div>
        <Input className="border-none outline-none" value={stakeAmount} onChange={handleStakeAmountChange} />
      </div>
      <div className="flex justify-center align-middle">
        {yenAllowance?.gt(0) ? (
          <button
            onClick={handleStake}
            className="border bg-white text-[#159374] border-[#159374] rounded-full  py-1  text-center w-1/2"
          >
            Stake {isLoading && <LoadingOutlined />}
          </button>
        ) : (
          <button
            onClick={handleApprove}
            className="border bg-white text-[#159374] border-[#159374] rounded-full  py-1  text-center w-1/2"
          >
            Approve YEN {isLoading && <LoadingOutlined />}
          </button>
        )}
      </div>
    </div>
  );
}

export default Index;
