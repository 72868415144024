// import Countdown from 'antd/lib/statistic/Countdown';
import React from 'react';
import Countdown from 'react-countdown';

const renderer = ({ days, hours, minutes, seconds }: any) => {
  return (
    <>
      {days}
      <span className="text-[#ffffff]  opacity-50"> Day </span>
      {hours}
      <span className="text-[#ffffff] opacity-50"> Hour </span>
      {minutes}
      <span className="text-[#ffffff] opacity-50"> Mins </span>
      {seconds}
      <span className="text-[#ffffff] opacity-50"> Sec </span>
    </>
  );
};

function Index() {
  const deadline = 1665928800000 + 86400 * 1000;

  return (
    <div className="'min-w-[100vw] min-h-[90vh] flex justify-center items-center h-full">
      <div className="inline-flex flex-col items-center justify-end w-auto h-32 space-y-12">
        <p className="text-3xl font-semibold leading-3 text-center text-white">Coming soon</p>
        <p className="text-base text-center text-white">It’s almost YEN time:</p>
        <p className="text-base font-semibold text-center text-[#159374] ">
          <Countdown date={deadline} renderer={renderer} />
        </p>
      </div>
    </div>
  );
}

export default Index;
