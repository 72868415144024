import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import classNames from 'classnames';
import { message } from 'antd';
import useContract from '@/hooks/chain/useContract';
import { useEthers } from '@usedapp/core';

function Claim(props: any) {
  const { mintInfo } = props;
  const { claimMintReward } = useContract();
  const [claimable, setClaimable] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { account } = useEthers();

  useEffect(() => {
    if (account && mintInfo) {
      setClaimable(mintInfo.claimable);
      setCountdown(mintInfo.endtime.mul(1000).toNumber());
    }
  }, [account, mintInfo]);

  const handleClaimMintReward = () => {
    if (!claimable) {
      message.info('Not Claimable Yet');
      return;
    }
    claimMintReward()
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((err) => {
        if(err.error?.message === "execution reverted: CRank: Mint maturity not reached"){
          message.error("Minimum term must be 3 days or greater");
        }
        else{
          message.error(err.error?.message || 'Request failed');
        }
      });
  };

  return (
    <div className="w-full mt-5">
      <button
        onClick={handleClaimMintReward}
        className={classNames(
          'border text-[#159374] border-[#159374] rounded-full  py-1  text-center w-1/2 text-base',
          {
            'bg-[#525252] text-gray-400': !claimable,
          },
        )}
      >
        {claimable && <p>Claim YEN Now</p>}
        {!claimable && (
          <p>
            Claim in{' '}
            <Countdown
              key={countdown}
              date={countdown}
              onComplete={() => {
                window.location.reload();
              }}
            />
          </p>
        )}
      </button>
    </div>
  );
}

export default Claim;
