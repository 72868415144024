import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { shortenIfAddress, useEthers } from '@usedapp/core';
import useContract from '@/hooks/chain/useContract';
import { ethers } from 'ethers';
import { useUpdate } from '@/hooks/useUpdate';

const menus = [
  // { label: 'Launch', link: '/launch', hideAfterLaunch: true },
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Mint', link: '/mint' },
  { label: 'Stake', link: '/stake' },
  // { label: 'Events', link: '/events' },
];

function Header() {
  const { activateBrowserWallet, account } = useEthers();
  const { getYenBalance } = useContract();

  const [yenBalance, setYenBalance] = useState(ethers.constants.Zero);

  const { pathname } = useLocation();

  const displayAddress = useMemo(() => {
    if (!account) return null;

    return shortenIfAddress(account);
  }, [account]);

  const { updateByTimer } = useUpdate();

  useEffect(() => {
    getYenBalance().then((res) => {
      setYenBalance(res);
    });
  }, [getYenBalance, updateByTimer]);

  const deadline = 1665928800000 + 86400 * 1000;
  const nowtime = +new Date();
  const isCountDown = deadline - nowtime >= 0;

  return (
    <div className="w-full bg-[#181A21]">
      <div className="flex items-center justify-between px-5">
        <div className="flex items-center justify-between ">
          {menus
            .map((item) => (
              <div
                className="p-5 font-bold transition-all cursor-pointer hover:text-[#159374] hover:scale-110"
                key={item.label}
              >
                <span className={pathname == item.link ? 'font-bold text-[#159374]' : ''}>
                {/*  {item.href ? (*/}
                {/*    <a href={item.href} target="_blank" rel="noopener noreferrer">*/}
                {/*      {item.label}*/}
                {/*    </a>*/}
                {/*  ) : (*/}
                {/*    <Link to={item.link}> {item.label}</Link>*/}
                {/*  )}*/}
                  {<Link to={item.link}> {item.label}</Link>}
                </span>
              </div>
            ))}
        </div>
        <div className="flex items-center justify-center w-auto space-x-2 ">
          <div className="mr-5">YEN: {ethers.utils.formatEther(yenBalance)}</div>
          <button
            className="border border-[#159374] rounded-full px-3 py-1 w-[100px] text-center"
            // onClick={() => {
            //   message.info('Coming Soon!');
            // }}
          >
            Ethereum
          </button>
          {displayAddress ? (
            <button className="bg-[#159374] rounded-full px-3 py-1 min-w-[100px] text-center">{displayAddress}</button>
          ) : (
            <button
              className="bg-[#159374] rounded-full px-3 py-1 min-w-[100px] text-center"
              onClick={() => {
                activateBrowserWallet();
              }}
            >
              Connect
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
