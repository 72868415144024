import { useCallback, useMemo } from 'react';
import { useEthers } from '@usedapp/core';
import { YENPool__factory } from '@/contracts/typechain/';
import { BigNumber } from 'ethers';

const usePoolContract = () => {
  const { library, account } = useEthers();

  const poolContract = useMemo(() => {
    if (!(process.env.REACT_APP_POOL_CONTRACT_ADDRESS && library?.getSigner)) {
      return null;
    }
    return YENPool__factory.connect(process.env.REACT_APP_POOL_CONTRACT_ADDRESS, library!.getSigner());
  }, [library]);

  const getStakedYenValues = useCallback(async () => {
    if (!poolContract) {
      throw new Error('Not ready');
    }
    return poolContract.stakedYenValues();
  }, [poolContract]);

  const getStakeRecord = useCallback(async () => {
    if (!(poolContract && account)) {
      throw new Error('Not ready');
    }
    const [stakedYen, unstakeTimestamp, unstakeAmount] = await Promise.all([
      poolContract.stakedYen(account),
      poolContract.unstakeTimestamp(account),
      poolContract.unstakeAmount(account),
    ]);
    return { stakedYen, unstakeTimestamp, unstakeAmount };
  }, [poolContract, account]);

  const stake = useCallback(
    async (yenAmount: BigNumber) => {
      if (!poolContract) {
        throw new Error('Not ready');
      }

      const tx = await poolContract.stake(yenAmount);

      return tx.wait();
    },
    [poolContract],
  );

  const unstake = useCallback(
    async (yenAmount: BigNumber) => {
      if (!poolContract) {
        throw new Error('Not ready');
      }

      const tx = await poolContract.unstake(yenAmount);

      return tx.wait();
    },
    [poolContract],
  );

  const unstakeFetch = useCallback(async () => {
    if (!poolContract) {
      throw new Error('Not ready');
    }

    const tx = await poolContract.unstakeFetch();

    return tx.wait();
  }, [poolContract]);

  const claim = useCallback(
    async (who: string, yenAmount: BigNumber, whichDays: BigNumber[], v: number, r: string, s: string) => {
      if (!poolContract) {
        throw new Error('Not ready');
      }

      const tx = await poolContract.claim(who, yenAmount, whichDays, v, r, s);

      return tx.wait();
    },
    [poolContract],
  );

  return { getStakedYenValues, getStakeRecord, stake, unstake, unstakeFetch, claim };
};

export default usePoolContract;
