import { Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { BigNumber, ethers, utils } from 'ethers';
import BN from 'bignumber.js';
import Dialog from './dialog';
import useContract from '@/hooks/chain/useContract';
import { useEthers } from '@usedapp/core';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Countdown from 'react-countdown';
import { useUpdate } from '@/hooks/useUpdate';
import usePoolContract from '@/hooks/chain/usePoolContract';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useYenPrice } from '@/hooks/chain/useYenPrice';
import useDistInfo from '@/hooks/useDistInfo';

// const renderer = ({ days, hours, minutes, seconds }: any) => {
//   return (
//     <>
//       {days}:{hours}:{minutes}:{seconds}
//     </>
//   );
// };
function Index() {
  const { account, library } = useEthers();
  const { getDashboard, getGlobalRank, getFundBalance } = useContract();
  const { getStakedYenValues, getStakeRecord } = usePoolContract();
  const [totalStakedYen, setTotalStakedYen] = useState(BigNumber.from(0));
  const [stakeRecord, setStakeRecord] = useState<{ [key: string]: BigNumber }>({});
  const [globalRank, setGlobalRank] = useState<BigNumber>();
  const [data, setdata] = useState<any>({});
  const [fund, setFund] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { updateByTimer } = useUpdate();
  const isMounted = useIsMounted();
  const yenPrice = useYenPrice();
  const { nextDistTime } = useDistInfo();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (account && library) {
      getGlobalRank().then((res) => setGlobalRank(res));
      getFundBalance().then((res: any) => {
        if (res) {
          setFund(utils.formatEther(res));
        }
      });
      getDashboard().then((res) => {
        if (res) {
          setdata(res);
        }
      });
    }
  }, [account, library, getFundBalance, getGlobalRank, getDashboard, updateByTimer]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const _stakedYenValues = await getStakedYenValues();
        const _stakeRecord = await getStakeRecord();
        if (!isMounted.current) {
          return;
        }
        const _totalStakedYen = _stakedYenValues.reduce((accum, item) => accum.add(item), BigNumber.from(0));
        setTotalStakedYen(_totalStakedYen);
        setStakeRecord(_stakeRecord);
      } catch (error) {
        console.error(error);
      }
    };

    fetch();
  }, [getStakedYenValues, getStakeRecord, isMounted, updateByTimer]);

  const apy = useMemo(() => {
    const willReceive =
      totalStakedYen.gt(0) && stakeRecord.stakedYen?.gt(0) && fund
        ? utils.parseEther(fund).mul(5).div(100).mul(stakeRecord.stakedYen).div(totalStakedYen)
        : ethers.constants.Zero;

    const apy =
      yenPrice.gt(0) && stakeRecord.stakedYen?.gt(0)
        ? willReceive
            .mul(ethers.constants.WeiPerEther)
            .mul(ethers.constants.WeiPerEther)
            .div(yenPrice.mul(stakeRecord.stakedYen))
            .mul(365)
            .mul(100)
        : ethers.constants.Zero;

    return apy;
  }, [fund, stakeRecord.stakedYen, totalStakedYen, yenPrice]);

  return (
    <div className="min-w-[100vw] flex flex-col justify-center items-center h-full space-y-3 max-w-[95vw]">
      <div className="mt-4 text-2xl font-semibold text-center text-white">Dashboard</div>
      <div className="bg-[#272932] w-[600px] max-w-[95vw] flex flex-col items-center p-4 rounded-xl">
        <div>Global Rank</div>
        <span className="text-xl font-semibold text-[#159374]">{globalRank ? globalRank.toNumber() : '-'}</span>
        <div className="grid w-full grid-cols-2 pt-4 md:grid-cols-1 gap-x-4">
          <div className="flex justify-between">
            <div>
              <div>Active Minters</div>
              {/* <div>Active Stakes</div> */}
            </div>
            <div className="text-end">
              <div>{data.activeMinters ? data.activeMinters.toNumber() : '-'}</div>
              {/* <div>{data.activeStakes ? data.activeStakes.toNumber() : '-'}</div> */}
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              {/* <div>Total Transactions</div> */}
              <div>Time Since Genesis </div>
            </div>
            <div className="text-end">
              {/* <div>-</div> */}
              <div>
                {data.genesisTs
                  ? ((+new Date() - data.genesisTs.toNumber() * 1000) / 1000 / 3600 / 24).toFixed(0)
                  : '-'}{' '}
                days
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:flex-col w-[600px] justify-between gap-4 font-[300] max-w-[95vw]">
        <div className="flex flex-col items-center w-1/2 space-y-4 md:w-full">
          <div className="bg-[#272932] rounded-xl p-4  w-full space-y-2">
            <div className="flex justify-between">
              <span>Total Supply</span>
              <span>{data.totalSupply ? utils.formatEther(data.totalSupply) : '-'}</span>
            </div>
            <div className="flex justify-between">
              <span>Liquid Supply</span>
              <span>-</span>
            </div>
            <div className="flex justify-between">
              <span>Total Staked</span>
              <span>
                {totalStakedYen.gt(0) ? new BN(utils.formatEther(totalStakedYen)).dp(9).toFormat().toString() : '--'}
              </span>
            </div>
          </div>
          <div className="bg-[#272932] rounded-xl p-4  w-full space-y-2">
            <div className="flex justify-between">
              <span>Fund Total</span>
              <span>{fund ? `${Math.floor(fund * 1000) / 1000} ETH` : '--'}</span>
            </div>
            <div className="flex justify-between">
              <span>Estimated APY</span>
              <span>{`${apy.gt(0) ? new BN(utils.formatEther(apy)).dp(9).toFormat().toString() : '--'}%`}</span>
            </div>
            <div className="flex justify-between">
              <span>Div Countdown</span>
              {/* <span>--</span> */}
              <Countdown date={nextDistTime} key={nextDistTime} />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center w-1/2 md:w-full ">
          <div className="bg-[#272932] rounded-xl p-4  w-full space-y-2">
            <div className="flex justify-between">
              <span>Max Term,days</span>
              <span>{data.MaxTerm ? data.MaxTerm.toNumber() / 3600 / 24 : '-'}</span>
            </div>
            <div className="flex justify-between">
              <span className="flex items-center">
                AMP
                <Tooltip title="Reward Amplifier (AMP) is a time-dependent part of YEN Mint Reward calculation. It starts at 100 at Genesis and decreases by 1 every day until it reaches 1.">
                  <QuestionCircleOutlined className="ml-2" />
                </Tooltip>
              </span>
              <span>{data.AMP ? data.AMP.toNumber() : '-'}</span>
            </div>
            <div className="flex justify-between">
              <span className="flex items-center">
                EAA
                <Tooltip title="Early Adopter Amplifier (EAA) is a part of YEN Mint Reward calculation which depends on current Global Rank. EAA starts from 1% and decreases in a linear fashion by 0.1% per each 100,000 increase in Global Rank.">
                  <QuestionCircleOutlined className="ml-2" />
                </Tooltip>
              </span>
              <span>{data.EAA ? data.EAA.toNumber() / 10 : '-'} %</span>
            </div>
            {/* <div className="flex justify-between">
              <span>APY</span>
              <span>{data.APY ? data.APY.toNumber() : '-'} %</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex space-x-5">
        <button className="border text-[#159374] border-[#159374] rounded-full px-3 py-1 w-auto text-center">
          <Link to="/mint" className="flex items-center">
            Mint YEN
            <Tooltip title="Mint will cost 0.05 ETH and the fee goes into YEN Fund which will generate daily passive income for all YEN stakers.">
              <QuestionCircleOutlined className="ml-2" />
            </Tooltip>
          </Link>
        </button>
        <button className="border text-[#159374] border-[#159374] rounded-full px-3 py-1 w-auto text-center">
          <Link to="/stake">Stake YEN</Link>
        </button>
      </div>
      <Dialog open={isModalOpen} onOk={handleOk} onCancel={handleCancel}></Dialog>
    </div>
  );
}

export default Index;
